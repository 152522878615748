@use 'common'
.headline
    font-size: 32px
    color: #000
    margin: 0 0 30px 0
    text-align: center

.wrapper
    width: 100%

.inner
    margin: 0 -5px
    button
        display: block
        margin: 50px auto 0 auto

.filter
    display: flex
    overflow-x: scroll
    justify-content: flex-start
    margin-bottom: 1em
    &::-webkit-scrollbar
        display: none

    .filterButton
        padding: 0
        margin: 5px
        font-size: 14px
        font-weight: 600
        padding: 10px 20px
        border-radius: 30px
        border: none
        color: #000
        background-color: #F2F2F2
        cursor: pointer
        flex-shrink: 0
        display: inline-block
        &.active
            background-color: #000
            color: #fff

.single
    height: auto
    margin: 5px
    border-radius: .3em
    overflow: hidden
    position: relative

.overlay
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 1
    padding: 10px
    display: flex
    flex-direction: column
    justify-content: flex-end
    align-items: flex-start
    @media(max-width: 768px)
        padding: 5px

.logo
    background-color: #fff
    width: 50px
    height: 50px
    padding: .3em
    border-radius: .3em
    margin-bottom: .5em
    @media(max-width: 768px)
        width: 30px
        height: 30px
    > div
        height: 100%
