@use 'sass:math'
@use '../../app/styles/common'

$breakDesktop: 768px

.Configurator
	//margin: 10px
	font-family: common.$switzer
	p
		margin: 0
		font-size: 14px
		color: #000
		font-weight: 600

.Layout
	display: flex
	align-items: stretch
	flex-direction: column-reverse
	//background: #eeeeee
	//overflow: hidden
	//max-width: 1000px
	margin: 0 auto

.Controls
	flex-grow: 0
	flex-shrink: 0
	background: #f6f6f6
	padding: 1em
	display: flex
	flex-direction: column
	border-radius: 10px

.Main
	flex-grow: 1
	flex-shrink: 1
	position: relative

.Ratio
	position: relative
	width: 100%
	overflow: hidden
	border-radius: 10px

	&::before
		display: block
		content: ''
		padding-top: 100% * math.div(3, 4)
		width: 100%

	.RatioIn
		position: absolute
		width: 100%
		height: 100%
		top: 0
		left: 0
		right: 0
		bottom: 0
		display: flex
		justify-content: stretch
		align-items: stretch
		z-index: 1

.Background
	position: absolute
	width: 100%
	height: 100%
	top: 0
	left: 0
	right: 0
	bottom: 0
	z-index: 0

.BackgroundRadio
	margin-top: 1em
	label
		width: calc(20% - 10px)

.Canvas
	display: flex
	align-items: center
	justify-content: center
	white-space: nowrap
	flex-direction: column
	width: 100%
	transition: transform ease-in-out .3s, opacity ease-in-out .3s

	.Center
		display: block

.Preview
	transition: all ease-in-out .3s

.PreviewSvg
	transition: all ease-in-out .3s
	position: absolute
	width: 100%
	height: 100%
	top: 0
	left: 0
	right: 0
	bottom: 0

.BackgroundImage
	transition: opacity ease-in-out .3s

.FontLabel, .SizeLabel
	display: inline-block
	padding: 5px 10px
	border: 2px solid currentColor
	border-radius: 3px
	background-color: #fff
	span
		color: #000

.FontPicker
	> div > div
		display: flex
		flex-wrap: wrap
	label
		width: 70px
		height: 70px

.FontLabel
	border-radius: 50%
	width: 90%
	height: 90%
	display: flex
	align-items: center
	justify-content: center
	font-size: 20px

.ColorLabel
	width: 50px
	height: 50px
	display: inline-block
	overflow: hidden
	border-radius: 50%
	border: 2px solid currentColor
	box-sizing: border-box
	position: relative
	margin-right: 10px
	background-color: #fff

.ColorLabelInner
	border-radius: 50%
	position: absolute
	top: 50%
	left: 50%
	width: 80%
	height: 80%
	transform: translate(-50%, -50%)

.BackgroundLabel
	width: 100%
	padding-top: 70%
	position: relative
	display: inline-block
	border: 2px solid currentColor
	border-radius: 3px
	box-sizing: border-box
	img
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		object-fit: cover
		border-radius: 3px

.TextWrapper
	margin-bottom: 10px
	order: -1

.Line + .Line
	margin-top: 10px
	//border-top: 1px solid #cecece
	padding-top: 10px

.Line
	margin-bottom: 10px

.Word + .Word
	margin-top: 1em

.Word
	position: relative
	.RemoveWord
		position: absolute
		top: 0
		right: 0
		border-radius: 50%
		width: 25px
		height: 25px
		border: none
		transform: translate(30%, -40%)
		background-color: #555
		cursor: pointer
		color: #fff
		svg
			width: 10px
			height: 10px

.AddButton
	display: flex
	border: none
	padding: 0
	background-color: transparent
	font-size: 12px
	font-weight: 500
	margin-left: auto
	padding: 5px 10px
	background-color: #e8e8e8
	border-radius: 5px
	display: flex
	align-items: center
	cursor: pointer
	color: #000

	span
		position: relative
		width: 15px
		height: 15px
		background-color: #555
		border-radius: 50%
		margin-right: 5px
	svg
		width: 7px
		height: 7px
		transform: translate(-50%, -50%)
		color: #fff
		position: absolute
		top: 50%
		left: 50%

.AddLine
	span
		background-color: transparent
	svg
		color: #000
		width: 12px
		height: 12px

.TextArea
	width: 100%
	font-size: 18px
	font-family: common.$switzer
	padding: 10px
	border-radius: 5px
	background-color: #fff
	border: 1.5px solid #fff
	font-weight: 700
	outline: none
	margin-bottom: .3em
	&:focus
		outline: none

.ActiveWord
	border: 1.5px solid #000

.WordEdit
	margin: 2em 0

.Actions
	margin-top: 2em
	button
		width: 100%

.Price
	font-size: 32px
	color: #000
	font-weight: 600
	margin-bottom: 10px

.Info
	font-size: 14px!important
	margin-top: 5px!important

.Promotion
	position: absolute
	bottom: 3px
	right: 3px
	z-index: 100
	background-color: common.$red
	color: #fff
	font-size: 10px
	border-radius: 3px
	padding: 3px

@media (min-width: $breakDesktop)
	.Layout
		flex-direction: row-reverse
		border-radius: 0
		align-items: flex-start

	.Main
		margin-right: 1em
		width: 70%
		position: sticky
		top: 20px

	.Ratio
		border-radius: 10px
		overflow: hidden

	.Controls
		border-radius: 10px
		width: 30%

	.Promotion
		font-size: 12px
		bottom: unset
		top: 5px
		right: 5px
		padding: 5px
