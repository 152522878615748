@use 'common'

.wrapper
	display: flex
	z-index: 10
	position: absolute
	top: 0
	left: 0
	width: 100%
	padding: 20px 0
	padding-top: 50px
	&.filled
		padding: 10px 0
		background-color: #fff
		border-bottom: 1px solid #000
		.menuItem, .cart, .logo
			color: #000
	&.filledHeader
		background-color: transparent
		.menuItem, .cart, .logo
			color: #000
		@media(max-width: 768px)
			.open
				svg
					color: #000
	&.filledColor
		background-color: #fff
		border-bottom: 1px solid #000
		.menuItem, .cart, .logo
			color: #000

.logo
	color: #000
	flex-shrink: 0
	height: auto
	width: 150px
	z-index: 2

.inner
	display: flex
	align-items: center
	justify-content: space-between
	width: 100%

.menu
	list-style: none
	display: flex
	padding: 0
	margin: 0
	align-items: center

.menuItem
	color: #000
	font-size: 16px
	a
		z-index: 2
		position: relative
.hasSubItems
	@media(min-width: 768px)
		&:hover
			&:before
				content: ''
				position: absolute
				top: 0
				height: 100px
				background-color: transparent
				width: 100%
				left: 0
				//z-index: -1
			.menuItemSub
				display: flex
.menuItemSub
	.menuItem
		position: relative
		.menuItemImage
			img
				transition: transform .35s ease
		&:hover
			.menuItemImage
				img
					transform: scale(1.1)
		a
			position: initial
			&:before
				content: ''
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
				cursor: pointer
	@media(min-width: 768px)
		position: absolute
		bottom: 0
		transform: translateY(calc(100% + 2px))
		background-color: #fff
		width: 100%
		left: 0
		display: none
		justify-content: center
		padding: 10px 0 20px 0
		ul
			width: 100%
			max-width: calc(100% - 80px)
			margin: 0 auto
			padding: 0
			list-style: none
			display: flex
			flex-wrap: wrap
			align-items: flex-start
			.menuItem + .menuItem
				margin: 10px
			@media(min-width: 1200px)
				max-width: 1100px
		.menuItem
			display: flex
			padding: 10px
			border-radius: 5px
			background-color: #fff
			color: #000
			width: calc((100% / 3) - 20px)
			margin: 10px
			a
				text-decoration: underline
			article
				p
					padding: 0
					margin: 5px 0 0 0
					font-size: 15px
			.menuItemImage
				flex-shrink: 0
				position: relative
				width: 100px
				height: 100px
				border-radius: 5px
				overflow: hidden
				margin-right: 10px

.menuItem + .menuItem
	margin-left: 35px

.open, .close
	display: none

.cart
	width: 25px
	height: 25px
	position: relative
	color: #000
	padding: 0
	background-color: transparent
	border: none
	margin: 0 35px 0 auto
	cursor: pointer
	z-index: 2

.cartCount
	position: absolute
	top: 0
	right: 0
	transform: translate(50%, -40%)
	background-color: #000
	width: 20px
	height: 20px
	border-radius: 50%
	font-size: 12px
	display: flex
	align-items: center
	justify-content: center
	color: #fff

@media(max-width: 480px)
	.wrapper
		padding-top: 70px!important

@media(max-width: 768px)
	.wrapper
		z-index: 101
		padding: 15px 0
		padding-top: 50px
		&.filled
			.open
				svg
					color: #000
	.menu
		position: fixed
		top: 0
		left: 0
		width: 100%
		height: 100%
		background-color: common.$yellow
		z-index: 10
		display: flex
		flex-direction: column
		//justify-content: center
		padding: 100px 20px 20px 20px
		align-items: flex-start
		opacity: 0
		pointer-events: none
		transition: opacity .35s ease
		overflow-y: scroll
		&:before
			content: ''
			position: fixed
			top: 0
			left: 0
			width: 100%
			height: 70px
			background-color: common.$yellow

	.menuOpened
		position: fixed
		.menu
			opacity: 1
			pointer-events: auto
		.logo
			color: #000
			z-index: 11
			position: relative

	.menuItem + .menuItem
		margin: 15px 0 0 0

	.menuItem
		color: #000
		font-size: 20px
		width: 100%

	.menuItemSub
		ul
			margin: 0
			padding: 0
			list-style: none
			margin-top: 15px
		.menuItem
			display: flex
			background-color: #fff
			padding: 10px
			border-radius: 5px
			article
				p
					padding: 0
					margin: 0
					font-size: 14px
			.menuItemImage
				width: 90px
				height: 90px
				position: relative
				flex-shrink: 0
				border-radius: 5px
				overflow: hidden
				margin: 0 10px 0 0

	.logo
		width: 100px

	.open, .close
		display: block
		width: 30px
		height: 30px
		padding: 0
		background-color: transparent
		border: none
		svg
			width: 30px
			height: auto
			color: #000

	.close
		z-index: 10
		svg
			width: 25px
			height: auto
			color: #000

	.cart
		width: 20px
		height: 22px
