@use 'common'

.wrapper
    //width: 100%

.headline
    font-size: 24px
    color: #000
    @media(max-width: 768px)
        font-size: 16px

.content
    font-size: 16px
    color: #000
    video
        max-width: 100%
        border-radius: 10px
