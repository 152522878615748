@use 'common'

.wrapper
	width: 100%

.headline
	text-align: center
	max-width: 700px
	margin: 0 auto 50px auto
	font-size: 32px
	color: #000

.inner
	display: flex
	flex-wrap: wrap
	margin: 0 -10px

.single
	width: calc((100% / 3) - 20px)
	margin: 10px
	position: relative
	&:hover
		.image
			> div
				transform: scale(1.1)

.image
	position: relative
	width: 100%
	padding-top: 100%
	overflow: hidden
	border-radius: 10px
	> div
		transition: transform .35s ease

.content
	display: flex
	justify-content: space-between
	align-items: flex-start
	margin-top: 10px
	z-index: 1
	h3
		width: 70%
		flex-shrink: 0
		margin: 0
		font-size: 18px
		color: #000
	a
		min-width: unset
		z-index: 2
		&:hover
			transform: none
		&::after
			content: ''
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			z-index: 2

@media(max-width: 768px)
	.single
		width: calc(50% - 10px)
		margin: 5px

	.inner
		margin: 0 -5px

	.headline
		font-size: 24px
		margin: 0 auto 30px auto

	.content
		h3
			//width: 100%
			flex-shrink: initial
			font-size: 14px
		> a
			padding: 5px 10px
			font-size: 14px
