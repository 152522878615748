@use 'common'

.wrapper
	width: 100%
	background-color: common.$blue

.inner
	color: #fff
	display: flex
	justify-content: space-around
	align-items: center
	flex-wrap: wrap
	.logo
		width: calc(25% - 30px)
		max-width: 150px
		margin: 15px
	svg
		fill: #fff

.headline
	font-size: 32px
	color: #fff
	margin: 0 0 30px 0
	text-align: center

@media(max-width: 480px)
	.headline
		font-size: 24px
		margin-bottom: 15px
	.inner
		.logo
			width: calc(25% - 20px)
			margin: 10px
