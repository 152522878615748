.BasicLabel
    color: #1d1d1d
    width: 100%
    padding: 10px 15px
    border-radius: 5px
    width: 100%
    background-color: #fff
    border: 1.5px solid #fff
    font-weight: 600
    cursor: pointer
    margin: 2px 0

.BasicLabelInner

.Checked
    border: 1.5px solid #000
    .FontLabelInner
        color: #000
