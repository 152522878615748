.wrapper
	padding: 0 20px
	width: 100%
	box-sizing: border-box

	&.viewStickyHeader
		display: flex
		align-items: flex-start
		margin-top: 120px
		padding: 0
		@media(max-width: 768px)
			display: block
			margin-top: 40px

	@media(min-width: 768px)
		max-width: calc(100% - 80px)
		margin: 0 auto
		padding: 0

	@media(min-width: 1200px)
		//max-width: 1100px
