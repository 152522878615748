.RadioPicker
    pointer-events: auto
    margin-bottom: 10px
    &.ViewBasic
        .InputWrapper
            width: 100%

.RadioPickerInner
    display: flex
    flex-wrap: wrap
    margin-top: 10px

.InputWrapper
    position: relative
    margin: 0 5px 5px 0
    //display: inline-block
    flex-shrink: 0

.Input
    position: absolute
    top: 0
    left: 0
    width: 1px
    height: 1px
    visibility: hidden
    & + span
        color: #535353
        font-size: 18px
    &:checked + span
        color: #000
        //font-weight: 700

.InputLabel
    z-index: 1
    position: relative
