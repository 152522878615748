@use 'common'

.wrapper
	width: 100%
	background-color: common.$yellow
	padding: 50px 0 100px 0

.inner
	display: flex
	justify-content: space-between
	align-items: flex-start

.left
	a
		color: #000
		margin: 0
		font-size: 16px
		font-weight: 700
		text-transform: uppercase
	p
		color: #000
		margin: 0
		font-size: 16px
		font-weight: 400
	p + p
		margin: 1em 0 0 0
	h4
		margin: 0

.logo
	margin-bottom: 10px
	display: block
	svg
		color: #000
		width: 200px

.right
	display: flex

.menu
	color: #000
	list-style: none
	padding: 0
	a
		font-size: 16px
		font-weight: 700
		line-height: 2em

.menu:nth-child(2)
	margin-left: 70px
	a
		font-weight: 400

@media(max-width: 768px)
	.wrapper
		padding: 50px 0
	.inner
		flex-wrap: wrap
	.right, .left
		width: 100%
	.right
		flex-wrap: wrap
	.menu
		width: 100%
	.menu:nth-child(2)
		margin-left: 0
