@use 'common'
.wrapper
	width: 100%
	position: relative
	// height: 90vh
	// min-height: 500px
	// max-height: 700px
	display: flex
	justify-content: center
	align-items: center
	padding: 0
	margin-top: 100px
	&.side
		display: flex
		.inner
			color: #000
			width: 50%
			margin: 0 auto 0 0
			text-align: left
			.headline
				color: #000
		.background
			width: 50%
			right: 0
			left: unset
			border-radius: 0 0 0 10px
			overflow: hidden
			&::after
				display: none
		@media(max-width: 768px)
			flex-wrap: wrap
			background-color: #fff
			padding-top: 70px
			flex-direction: row-reverse
			max-height: unset
			height: unset
			.background
				width: calc(100% - 40px)
				position: relative
				top: unset
				left: unset
				bottom: unset
				right: unset
				padding-top: calc(100% - 40px)
				height: unset
				border-radius: 10px
				overflow: hidden
			.inner
				width: 100%
				text-align: center
				.headline
					padding-top: 10px
					//background-color: #fff
					border-radius: 5px

.background
	// +common.cover
	overflow: hidden
	position: relative
	width: 100%
	padding-top: 56%
	border-radius: 10px
	overflow: hidden
	video
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		object-fit: cover
	&::after
		content: ''
		+common.cover
		background-color: rgba(0, 0, 0, .5)

.inner
	max-width: 800px
	z-index: 1
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	text-align: center
	margin: 0 auto

.headline
	color: common.$yellow
	font-weight: 800
	font-family: common.$switzer
	font-size: 56px
	padding: 0
	margin-top: 0
	margin-bottom: .3em
	text-transform: uppercase

.links
	a
		margin: 10px

@media(max-width: 768px)
	.headline
		font-size: 32px
	.wrapper
		margin-top: 70px
		.background
			height: 100%
			padding-top: 80%
@media(max-width: 768px)
	.wrapper
		.background
			padding-top: 150%
