@use 'common'

.wrapper
    width: 100%

.headline
    display: flex
    justify-content: space-between
    width: 100%

    h2
        max-width: 290px

    .logos
        display: flex
        align-items: center
        .logo
            width: 70px
            margin-left: 30px
    @media(max-width: 768px)
        flex-wrap: wrap
        justify-content: flex-start
        h2
            width: 100%
        .logos
            width: 100%
            margin: 0 -10px
            .logo
                margin: 0 10px

.inner
    width: 100%
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    .single
        width: calc((100% / 4) - 20px)
        //margin: 0 10px
        padding: 15px
        padding-top: 30%
        position: relative
        border-radius: 10px
        overflow: hidden
        .image
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            z-index: -1
        // a:before
        //     content: ''
        //     position: absolute
        //     top: 0
        //     left: 0
        //     width: 100%
        //     height: 100%
        //     z-index: 3
    @media(max-width: 1100px)
        margin: 0 -10px
        width: calc(100% + 20px)
        .single
            width: calc(50% - 20px)
            margin: 10px
            padding-top: 60%
    @media(max-width: 768px)
        margin: 10px 0 0 0
        width: 100%
        .single
            width: 100%
            margin: 10px 0
            padding: 10px
            padding-top: 120%
