@use 'common'

.wrapper
	width: 100%
	color: #000

.headline
	font-size: 32px
	color: #000
	margin: 0 0 30px 0
	text-align: center
	@media(max-width: 480px)
		font-size: 24px
		margin-bottom: 15px

.inner
	display: flex
	margin: 0 -15px
	flex-wrap: wrap
	@media(max-width: 480px)
		margin: 0

.item
	width: calc((100% / 3) - 30px)
	margin: 15px
	position: relative

	h3
		font-size: 20px
		margin: 10px 0

	a
		margin-top: 10px

	.image
		width: 100%
		padding-top: 100%
		position: relative
		border-radius: 10px
		overflow: hidden

	.number
		background-color: common.$yellow
		width: 35px
		height: 35px
		border-radius: 50%
		text-align: center
		display: flex
		align-items: center
		justify-content: center
		position: absolute
		top: 0
		left: 0
		z-index: 1
		transform: translate(-50%, -50%)
		span
			font-size: 20px
			font-weight: 700

	@media(max-width: 768px)
		width: calc(50% - 30px)
		margin: 15px
		h3
			font-size: 16px
			line-height: 1.3em
			margin: 10px 0 5px 0
		article
			font-size: 14px

	@media(max-width: 480px)
		width: 100%
		margin: 0
		display: flex
		.image
			width: 100px
			flex-shrink: 0
			padding-top: 0
			height: 100px
			margin-right: 10px
		h3
			margin-top: 0
		& + &
			margin-top: 25px
