@use 'common'

.wrapper
	width: 100%
	background-color: common.$yellow
	text-align: center
	&.viewStickyHeader
		background-color: transparent
		.form
			background-color: #F2F2F2
.form
	max-width: 500px
	padding: 30px
	border-radius: 10px
	margin: 0 auto
	background-color: #fff
	text-align: left
	input
		width: 100%
		color: #000
		font-size: 18px
		margin-bottom: 10px
		border: 1px solid #000
		border-radius: 3px
		padding: 10px
		font-family: common.$switzer
		&:focus, &:active
			outline: none
			border-color: common.$yellow
	textarea
		width: 100%
		color: #000
		font-size: 18px
		border: 1px solid #000
		border-radius: 3px
		padding: 10px
		font-family: common.$switzer
		&:focus, &:active
			outline: none
			border-color: common.$yellow
	button
		width: 100%
		margin-top: 10px

	label
		margin-top: 10px
		display: inline-block
		svg
			color: #000
			width: 15px
			height: 15px

.headline
	font-size: 32px
	color: #000
	margin: 0 0 30px 0

.success
	padding: 10px
	border-radius: 5px
	background-color: #fff
	font-size: 18px
	color: common.$transactionGreen
	display: inline-block

.fileMissing
	font-size: 18px
	color: #000

@media(max-width: 768px)
	.headline
		font-size: 24px

	.form
		padding: 20px

.sending
	pointer-events: none
	opacity: .3
