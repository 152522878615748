.RangeSlider

    .Input
        pointer-events: auto
        margin-bottom: 10px
        -webkit-appearance: none
        width: 100%
        height: 7px
        border-radius: 5px
        background: #d3d3d3
        outline: none
        -webkit-transition: .2s
        transition: opacity .2s
        &::-webkit-slider-thumb
            -webkit-appearance: none
            appearance: none
            width: 20px
            height: 20px
            border-radius: 50%
            background: #000
            cursor: pointer
        &::-moz-range-thumb
            width: 20px
            height: 20px
            border-radius: 50%
            background: #000
            cursor: pointer
