.FontLabel
    margin: 0 5px 5px 0
    display: inline-block
    background-color: #fff
    border-radius: 50%
    width: 64px
    height: 64px
    flex-shrink: 0
    position: relative
    display: flex
    align-items: center
    justify-content: center
    position: relative
    cursor: pointer
    border: 1.5px solid #fff
    color: #1d1d1d

.FontLabelInner
    &::after
        content: ''
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        z-index: 1
        cursor: pointer

.Checked
    border: 1.5px solid #000
    .FontLabelInner
        color: #000
