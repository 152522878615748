.wrapper
	padding: 60px 20px
	width: 100%
	box-sizing: border-box
	color: #000
	&.post
		padding-top: 20px
	a
		text-decoration: underline

	@media(min-width: 768px)
		max-width: 630px
		margin: 0 auto
		padding: 100px 0
		&.post
			padding-top: 40px
