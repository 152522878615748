@use 'common'

.wrapper
	width: 100%
	color: #000

.headline
	font-size: 32px
	color: #000
	margin: 0 0 30px 0
	text-align: center
	@media(max-width: 480px)
		font-size: 24px
		margin-bottom: 15px

.inner
	display: flex
	flex-wrap: wrap
	margin: 0 -15px

	.item
		width: calc((100% / 3) - 30px)
		margin: 15px

		h3
			margin: 10px 0 5px 0
			font-size: 20px

		p
			margin: 0

		.insta
			display: flex
			align-items: center
			margin-top: 10px
			margin-left: -5px
			svg
				width: 30px
				height: 30px

		.email
			text-decoration: underline

		.image
			width: 100%
			padding-top: 100%
			position: relative
			border-radius: 10px
			overflow: hidden

	@media(max-width: 480px)
		margin: 0 -10px
		.item
			width: calc(50% - 20px)
			margin: 10px

			h3
				font-size: 18px

			p
				font-size: 14px
