.RadioPicker
	pointer-events: auto
	margin-bottom: 10px

.InputWrapper
	position: relative
	margin: 0 5px 5px 0
	display: inline-block

.Input
	position: absolute
	top: 0
	left: 0
	width: 1px
	height: 1px
	visibility: hidden
	& + span
		color: #fff
		font-size: 18px
	&:checked + span
		color: #000
		font-weight: 700

.InputLabel
	z-index: 1
	position: relative
