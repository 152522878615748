@use 'common'
.btn
	padding: 0
	background-color: common.$yellow
	color: #000
	min-width: 205px
	text-align: center
	padding: 10px 20px
	border-radius: 5px
	cursor: pointer
	font-weight: 600
	font-size: 16px
	font-family: common.$switzer
	transition: all .35s ease
	display: inline-block
	border: none
	text-decoration: none!important
	@media (hover: hover) and (pointer: fine)
		&:hover
			transform: translateY(-3px)

.viewSmall
	font-size: 12px
	display: inline-block !important
	width: auto !important
	padding: 5px 10px
	//background-color: #e8e8e8
	border-radius: 5px
	font-weight: 500
	color: 000

.viewStickyHeader
	width: 100%
	padding: 20px

.viewBlack
	background-color: #000
	color: #fff

.viewLight
	color: #000
	background-color: #fff

.viewInspiration
	color: #000
	background-color: #fff
	min-width: unset
	@media(max-width: 768px)
		font-size: 10px
		padding: 5px
	@media(max-width: 350px)
		font-size: 8px

.disabled
	pointer-events: none
	opacity: .5

.inverted
	background-color: common.$blue
	color: common.$yellow

.viewEmptyCart
	padding: 0
	background-color: transparent
	min-width: unset
	color: common.$red
	font-size: 14px
	margin-bottom: 10px

.viewTransaction
	background-color: common.$transactionGreen
	color: #fff

.invertedMobile
	@media(max-width: 768px)
		background-color: common.$blue
		color: common.$yellow

.viewTextLink
	padding: 0
	border: none
	color: #000
	font-weight: 500
	background-color: transparent
	width: unset
	display: inline-block !important
	width: auto !important
	min-width: unset
	text-decoration: underline !important
	font-size: 12px
	margin: 10px 0
