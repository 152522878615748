@use 'common'
.wrapper
	width: 100%
	position: relative
	height: 90vh
	// min-height: 500px
	max-height: 750px
	display: flex
	justify-content: center
	align-items: center
	padding: 0
	margin-top: 100px
	>div
		height: 100%

.tiles
	display: grid
	display: grid
	grid-template-areas: "primary tertiary" "primary secondary" "primary secondary" "primary secondary" "primary secondary" "primary secondary" "primary secondary" "primary secondary"
	gap: 10px
	height: 100%
	@media(max-width: 768px)
		grid-template-areas: "primary" "primary" "primary" "secondary" "secondary" "secondary" "tertiary"

.item
	position: relative
	overflow: hidden
	border-radius: 10px
	display: flex
	video, img
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		object-fit: cover
	h1
		z-index: 1
		margin: 20px
		color: #000
		font-size: 26px
		max-width: 100%
		width: 100%
		margin-top: auto
		display: flex
		justify-content: space-between
		a
			display: inline-flex
			max-width: 320px
			align-items: center
			cursor: pointer
			&::after
				content: ''
				+common.cover
				z-index: 2
			&:hover
				text-decoration: underline
		.button
			width: 70px
			height: 70px
			padding: 0
			margin: 0
			border-radius: 50%
			border: none
			display: flex
			align-items: center
			justify-content: center
			background-color: common.$yellow
			flex-shrink: 0
			@media(max-width: 480px)
				width: 40px
				height: 40px
				svg
					width: 20px
		@media(max-width: 480px)
			font-size: 18px
			margin: auto 10px 10px 10px
			a
				max-width: calc(100% - 50px)
.item:nth-child(1)
	grid-area: primary
	h1
		color: #fff
	&::after
		content: ''
		+common.cover
		background-color: rgba(0, 0, 0, .1)
.item:nth-child(2)
	grid-area: tertiary
	background-color: common.$yellow
	h1
		margin-top: auto
		margin-bottom: auto
.item:nth-child(3)
	grid-area: secondary

.inner
	width: 100%
	height: 100%

@media(max-width: 768px)
	.headline
		font-size: 32px
	.wrapper
		margin-top: 70px
		.background
			height: 100%
			padding-top: 80%
@media(max-width: 768px)
	.wrapper
		.background
			padding-top: 150%
