@use 'common'

.wrapper
	width: 100%
	text-align: center

.inner
	display: flex
	flex-wrap: wrap
	margin: 0 -5px

.single
	padding: 0
	position: relative
	width: calc((100% / 3) - 10px)
	padding-top: calc((100% / 3) - 10px)
	margin: 5px
	border-radius: 10px
	overflow: hidden
	border: none
	background-color: #fff

.headline
	font-size: 32px
	color: #000
	margin: 0 0 30px 0

@media(max-width: 480px)
	.single
		width: calc(50% - 10px)
		padding-top: calc(50% - 10px)
		border-radius: 5px

	.headline
		font-size: 24px
		margin-bottom: 15px
