@use 'common'

.wrapper
	width: 100%

.inner
	display: flex
	align-items: center
	justify-content: space-between
	&.right
		flex-direction: row-reverse

.image
	position: relative
	width: 50%
	padding-top: 50%
	border-radius: 10px
	overflow: hidden
	&.klenba
		padding-top: 50%
		border-radius: 50% 50% 10px 10px
	&.portrait
		padding-top: 65%
	video
		width: 100%
		height: 100%
		object-fit: cover
		position: absolute
		top: 0
		left: 0

.content
	width: calc(50% - 40px)

.icon
	width: 60px

.headline
	font-size: 32px
	color: #000
	margin: 0

.description
	font-size: 18px
	line-height: 1.5em

.videoMiniture
	position: absolute
	bottom: 0
	left: 0
	padding: 10px
	width: 100%
	display: flex
	z-index: 1
	align-items: flex-end
	flex-wrap: wrap
	button
		height: auto
		margin-top: 10px
	.videoThumbnail
		position: relative
		height: 140px
		width: 80px
		border: 2px solid #fff
		border-radius: 5px
		margin-right: 10px
		box-shadow: 0px 0px 58px 1px rgba(0,0,0,0.75)
		z-index: -1
		flex-shrink: 0
		video
			border-radius: 5px
			overflow: hidden

@media(max-width: 768px)
	.inner
		flex-wrap: wrap

	.image
		width: 100%
		padding-top: 100%
		&.klenba
			padding-top: 100%
			border-radius: 50% 50% 10px 10px
		&.portrait
			padding-top: 150%

	.content
		width: 100%
		margin-bottom: 50px
		text-align: center

	.headline
		font-size: 24px

	.description
		font-size: 16px

	.icon
		margin: 0 auto
