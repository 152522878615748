@use 'common'

.wrapper
	width: 100%
	text-align: center

.list
	max-width: 900px
	margin: 0 auto
	list-style: none
	padding: 0
	display: flex
	flex-wrap: wrap

.single
	width: calc((100% / 3) - 80px)
	margin: 40px
	text-align: center
	h3
		font-size: 20px
		color: #000

.icon
	width: 90px
	margin: 0 auto
	img
		filter: drop-shadow(0px 0px 5px rgb(77 178 116 / 0.7))


@media(max-width: 768px)
	.list
		margin: 0 -15px
	.single
		width: calc(50% - 30px)
		margin: 15px
		h3
			font-size: 16px
			margin-top: 5px

	.icon
		width: 60px
