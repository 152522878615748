@use 'common'

.wrapper
	width: 100%
	&.viewTwo
		.inner
			display: flex
			flex-wrap: wrap
			grid-gap: 0
			margin: 0 -10px
		.single, .single:nth-child(1), .single:nth-child(2), .single:nth-child(3)
			width: calc(50% - 20px)
			margin: 10px
			padding-top: 50%

.inner
	display: grid
	grid-template-columns: 1fr 1fr 1fr 1fr
	grid-template-rows: 1fr 1fr
	grid-gap: 15px

.single:nth-child(1)
	grid-column: 1 / 3
	grid-row: 1 / 3

.single:nth-child(2)
	grid-column: 3 / 5
	grid-row: 1
	padding-top: 50%

.single:nth-child(3)
	grid-column: 3 / 5
	grid-row: 2
	padding-top: 50%

.single
	position: relative
	width: 100%
	padding-top: 100%
	border-radius: 10px
	overflow: hidden
	&:hover
		.image
			transform: scale(1.1)
	> a
		z-index: 1
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		padding: 10px
		box-sizing: border-box
		span
			background-color: common.$blue
			color: common.$yellow
			padding: 5px 10px
			border-radius: 5px
			display: inline-flex
			align-items: center
			justify-content: center
			font-size: 16px

.image
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	transition: transform .35s ease

@media(max-width: 768px)
	.inner
		display: flex
		flex-wrap: wrap
		grid-gap: 0
		margin: 0 -5px
	.single, .single:nth-child(1), .single:nth-child(2), .single:nth-child(3)
		width: calc(50% - 10px)
		margin: 5px
		padding-top: 50%
