@use 'common'

.wrapper
	position: fixed
	display: flex
	justify-content: center
	align-items: center
	width: 100%
	height: 100vh
	z-index: 101
	opacity: 0
	pointer-events: none
	transition: opacity .35s ease
	&::after
		content: ''
		position: fixed
		top: 0
		left: 0
		width: 100%
		height: 100%
		background-color: rgba(0, 0, 0, .7)
	&.visible
		opacity: 1
		pointer-events: auto

.inner
	background-color: #fff
	border: 1px solid #000
	border-radius: 5px
	padding: 20px
	box-sizing: border-box
	color: #000
	min-width: 290px
	position: relative
	z-index: 1
	max-width: 300px

.headline
	padding: 0
	margin: 0 0 .5em 0
	font-size: 24px

.cartLink
	margin-bottom: 10px
	display: inline-block
	text-decoration: underline

.items
	padding: 0
	list-style: none
	margin: 0

.item
	display: flex
	align-items: flex-start
	background-color: #fff
	padding: 10px 20px 10px 10px
	border-radius: 5px
	box-sizing: border-box
	border: 1px solid #000
	color: #000
	font-size: 14px
	font-weight: 400
	line-height: 1
	position: relative

.item + .item
	margin-top: 10px

.itemImage
	width: 40px
	height: 40px
	position: relative
	overflow: hidden
	border-radius: 3px
	margin-right: 10px
	flex-shrink: 0

.itemHeadline
	margin: 0

.itemQuantity
	margin-left: 10px

.itemPrice
	margin-top: 5px
	display: inline-block

.itemRemove
	width: 10px
	height: 10px
	padding: 0
	background-color: transparent
	color: #000
	border: none
	margin-left: 10px
	cursor: pointer
	position: absolute
	top: 3px
	right: 5px
	svg
		stroke-width: 5px
		pointer-events: none

.actions
	margin-top: 50px
	button
		width: 100%
	p
		margin: 0 0 .5em

.close
	width: 15px
	height: 15px
	padding: 0
	border: none
	border-radius: 0
	background-color: transparent
	cursor: pointer
	top: 10px
	right: 10px
	position: absolute
	color: #000

.payments
	width: 170px
	margin: 10px auto 0 auto

.delivery
	margin-bottom: 10px
	display: inline-block

.deliveryIcon
	width: 20px
	height: 15px
	position: relative
	display: inline-block
	margin-right: 5px
	transform: translateY(2px)

@media(min-width: 768px)
	.inner
		width: 100%
		max-width: 430px
		padding: 30px

	.item
		font-size: 18px

	.itemImage
		width: 50px
		height: 50px
		border-radius: 5px
		margin-right: 10px

	.itemHeadline
		font-size: 18px

	.actions
		font-size: 18px
		p
			margin-bottom: 20px
